import React from 'react';
import './App.css'; // Global styles
import Navbar from './components/Navbar'; // Navbar component
import Home from './pages/Home'; // Home component
import AdminDashboard from './pages/AdminDashboard'; // AdminDashboard component
import { Routes, Route, useLocation } from 'react-router-dom'; // useLocation for conditional rendering
import Trips from './components/Trips'; // Trips component
import TripDetail from './pages/TripDetail'; // TripDetail component
import Events from './components/Events'; // Events component
import SignUp from './components/SignUp'; // SignUp component
import SignIn from './components/SignIn'; // SignIn component
import UserProfile from './pages/UserProfile'; // UserProfile component
import AboutUs from './pages/AboutUs'; // AboutUs component
import AlumniSpotlight from './components/AlumniSpotlight'; // AlumniSpotlight component
import ProductsPage from './pages/Products'; // ProductsPage component
import ShoppingCart from './components/ShoppingCart'; // ShoppingCart component
import Checkout from './pages/Checkout'; // Checkout component (replaces PaymentForm as part of the flow)
import OrderConfirmation from './pages/OrderConfirmation'; // Order Confirmation component

// Importing admin dashboard sub-routes as components
import ManageUsers from './components/UserManagement'; // Manage Users component
import ApprovePhotos from './components/PhotoApproval'; // Approve Photos component
import OrderTracking from './components/OrderTracking'; // Order Tracking component
import EventsAndTrips from './components/EventsAndTrips'; // Events and Trips component

// Import the AuthContext for authentication
import { AuthProvider } from './context/AuthContext';
import CartProvider from './context/CartContext'; // Importing default CartProvider


function App() {
  const location = useLocation(); // Get the current route

  return (
    <AuthProvider>
      <CartProvider>
        <div className="App">
          {/* Conditionally render Navbar except on AdminDashboard */}
          {location.pathname !== '/admin-dashboard' && !location.pathname.startsWith('/admin-dashboard') && <Navbar />}

          <Routes>
            {/* Public routes with Navbar */}
            <Route path="/" element={<Home />} /> {/* Home route */}
            <Route path="/trips" element={<Trips />} /> {/* Trips route */}
            <Route path="/trips/:tripId" element={<TripDetail />} /> {/* Trip detail route */}
            <Route path="/events" element={<Events />} /> {/* Events route */}
            <Route path="/signup" element={<SignUp />} /> {/* SignUp route */}
            <Route path="/signin" element={<SignIn />} /> {/* SignIn route */}
            <Route path="/userprofile" element={<UserProfile />} /> {/* UserProfile route */}
            <Route path="/about" element={<AboutUs />} /> {/* AboutUs route */}
            <Route path="/alumni-spotlight" element={<AlumniSpotlight />} /> {/* Alumni Spotlight route */}
            <Route path="/alumni-spotlight/:id" element={<AlumniSpotlight />} /> {/* Alumni detail route */}

            {/* Shop Routes */}
            <Route path="/shop" element={<ProductsPage />} /> {/* Products page route */}
            <Route path="/shop/cart" element={<ShoppingCart />} /> {/* Shopping Cart route */}
            <Route path="/shop/checkout" element={<Checkout />} /> {/* Checkout route (includes PaymentForm) */}
            <Route path="/shop/order-confirmation" element={<OrderConfirmation />} /> {/* Order confirmation after payment */}

            {/* Admin dashboard with nested routes */}
            <Route path="/admin-dashboard" element={<AdminDashboard />}>
              <Route path="manage-events-trips" element={<EventsAndTrips />} />
              <Route path="manage-users" element={<ManageUsers />} />
              <Route path="approve-photos" element={<ApprovePhotos />} />
              <Route path="order-tracking" element={<OrderTracking />} />
            </Route>
          </Routes>
        </div>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
