import React, { useState, useEffect, useContext } from "react";
import "./Checkout.css";
import { CartContext } from '../context/CartContext';
import { getFirestore, collection, getDocs } from "firebase/firestore"; 

const ProductDisplay = ({ product, onSubmit }) => (
  <section>
    <div className="product">
      <img src={product.imgUrl} alt={product.name} />
      <div className="description">
        <h3>{product.name}</h3>
        <h5>
          Price: ${product.price !== undefined && product.price !== null ? product.price.toFixed(2) : 'Price not available'}
        </h5>
      </div>
    </div>
    <form onSubmit={onSubmit}>
      <button type="submit">Checkout</button>
    </form>
  </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Products() {
  const [products, setProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [stripePrices, setStripePrices] = useState(null);
  const { cart, addToCart } = useContext(CartContext);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const db = getFirestore();
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map(doc => doc.data());

        // Fetch Stripe Prices
        const stripeResponse = await fetch('/api/stripe/prices', {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_STRIPE_SECRET_KEY}`
          }
        });

        if (!stripeResponse.ok) throw new Error('Failed to fetch Stripe prices');

        const stripeData = await stripeResponse.json();
        setStripePrices(stripeData.data);

        // Map Stripe prices to products
        const productsWithPrices = productsList.map(product => {
          const stripePrice = stripeData.data.find(price => price.id === product.stripePriceId);
          if (stripePrice) {
            product.price = stripePrice.unit_amount / 100; // Convert cents to dollars
          }
          return product;
        });

        setProducts(productsWithPrices);

      } catch (error) {
        setError('Failed to load products or Stripe prices.');
        console.error('Error:', error);
      }
    };

    fetchProductData();
  }, []);

  const handleFormSubmit = async (product) => {
    try {
      if (!stripePrices) throw new Error('Stripe prices are not loaded.');

      const stripePrice = stripePrices.find(price => price.id === product.stripePriceId);
      if (!stripePrice) throw new Error('No matching Stripe price found for the selected product.');

      addToCart(product);
      
      const response = await fetch(process.env.REACT_APP_CHECKOUT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cart }),
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      const { url } = await response.json();
      setMessage("Redirecting to checkout...");
      window.location.href = url;
    } catch (error) {
      setError('Failed to create checkout session. Please try again.');
      setMessage('');
      console.error('Error:', error);
    }
  };

  if (error) return <div>{error}</div>;

  return (
    <>
      {message ? (
        <Message message={message} />
      ) : (
        <>
          {products.map((product) => (
            <ProductDisplay key={product.id} product={product} onSubmit={() => handleFormSubmit(product)} />
          ))}
          <div className="cart-summary">
            <h2>Your Cart</h2>
            {cart.length > 0 ? (
              cart.map((item, index) => (
                <div key={index}>
                  <p>{item.name}</p>
                  <p>Quantity: {item.quantity}</p>
                  <p>Price: ${item.price !== undefined && item.price !== null ? item.price.toFixed(2) : 'Price not available'}</p>
                </div>
              ))
            ) : (
              <p>Your cart is empty.</p>
            )}
          </div>
        </>
      )}
    </>
  );
}
