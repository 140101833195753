import React, { createContext, useEffect, useState, useRef, useCallback } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin status
  const auth = getAuth();
  const db = getFirestore();
  const INACTIVITY_TIMEOUT = 10 * 60 * 1000; // 10 minutes
  const inactivityTimer = useRef(null); // UseRef to persist the timer value across renders

  // useCallback ensures handleLogout is memoized and doesn't change between renders
  const handleLogout = useCallback(() => {
    signOut(auth).then(() => {
      setCurrentUser(null);
      setIsAdmin(false);
      alert('You have been logged out due to inactivity.');
    }).catch((error) => {
      console.error("Error signing out: ", error);
    });
  }, [auth]);

  useEffect(() => {
    const resetTimer = () => {
      clearTimeout(inactivityTimer.current);
      inactivityTimer.current = setTimeout(() => {
        handleLogout();
      }, INACTIVITY_TIMEOUT);
    };

    // Detect user activity and reset the inactivity timer
    const handleActivity = () => {
      resetTimer();
    };

    // Set up event listeners for activity detection
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);

        // Fetch user data from Firestore
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setIsAdmin(userData.isAdmin || false); // Check if the user is an admin
        }

        // Start inactivity timer when the user is logged in
        resetTimer();
      } else {
        setCurrentUser(null);
        setIsAdmin(false);
      }
    });

    return () => {
      unsubscribe();
      clearTimeout(inactivityTimer.current); // Clear the timer on unmount
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [auth, db, INACTIVITY_TIMEOUT, handleLogout]); // Add INACTIVITY_TIMEOUT and handleLogout to the dependency array

  return (
    <AuthContext.Provider value={{ currentUser, isAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};
