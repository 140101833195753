import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Products.css';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Replace the URL with your Gelato API endpoint
        const response = await axios.get('https://ecommerce.gelatoapis.com/v1/stores/YOUR_STORE_ID/products', {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_GELATO_API_KEY}`
          }
        });

        // Set the products to the state
        setProducts(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching products from Gelato:', err);
        setError('Error fetching products');
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="products-page">
      <h1>Products</h1>
      <div className="products-grid">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.previewImageUrl} alt={product.name} className="product-image" />
            <h3 className="product-title">{product.name}</h3>
            <p className="product-description">{product.description}</p>
            <p className="product-price"><strong>Price:</strong> ${product.retailPrice.toFixed(2)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsPage;
