import React, { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import './AdminDashboard.css';
import Sidebar from '../components/Sidebar';
import { db } from '../firebase';  // Ensure Firebase is properly configured
import { collection, getDocs } from 'firebase/firestore';
import Chart from '../components/Charts';
import { FaEnvelope, FaCalendarAlt, FaNewspaper, FaBox, FaImages, FaMapMarkerAlt, FaHome } from 'react-icons/fa'; // Icons

const AdminDashboard = () => {
  const [userData, setUserData] = useState({ totalUsers: 0, newUsers: 0 });
  const [events, setEvents] = useState([]);
  const [orders, setOrders] = useState({ newOrders: 0 });
  const [photos, setPhotos] = useState({ newPhotos: 0 });
  const [newsItems, setNewsItems] = useState({ newNews: 0 });
  const [sponsors, setSponsors] = useState(0);
  const [emails, setEmails] = useState([]);  // This will now store the `contactMessages`
  const [memorialItems, setMemorialItems] = useState(0);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [
          usersSnapshot,
          eventsSnapshot,
          ordersSnapshot,
          photosSnapshot,
          newsSnapshot,
          sponsorsSnapshot,
          contactMessagesSnapshot,
          memorialSnapshot,
        ] = await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(collection(db, 'events')),
          getDocs(collection(db, 'orders')),
          getDocs(collection(db, 'photos')),
          getDocs(collection(db, 'news')),
          getDocs(collection(db, 'sponsors')),
          getDocs(collection(db, 'contactMessages')),  // Fetch from contactMessages collection
          getDocs(collection(db, 'memorial'))
        ]);

        // Process user data
        const totalUsers = usersSnapshot.docs.length;
        const newUsers = usersSnapshot.docs.filter(user => user.data().isNew).length;
        setUserData({ totalUsers, newUsers });

        // Process events
        setEvents(eventsSnapshot.docs.map(doc => doc.data()));

        // Process orders
        const newOrders = ordersSnapshot.docs.filter(order => order.data().status === 'new').length;
        setOrders({ newOrders });

        // Process photos
        const newPhotos = photosSnapshot.docs.filter(photo => photo.data().status === 'pending').length;
        setPhotos({ newPhotos });

        // Process news items
        const newNews = newsSnapshot.docs.filter(news => news.data().status === 'pending').length;
        setNewsItems({ newNews });

        // Process sponsors
        setSponsors(sponsorsSnapshot.docs.length);

        // Process contact messages (instead of emails, as per your request)
        setEmails(contactMessagesSnapshot.docs.slice(0, 10).map(doc => doc.data())); // Fetch first 10 contact messages

        // Process memorial items
        const newMemorialItems = memorialSnapshot.docs.filter(item => item.data().status === 'pending').length;
        setMemorialItems(newMemorialItems);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="dashboard-container">
      <Sidebar /> {/* Sidebar remains on the left */}
      <div className="main-content">
        <div className="top-bar">
          <div className="top-bar-icons">
            <Link to="/emails-management"><FaEnvelope title="New Mail" /></Link>
            <Link to="/events-management"><FaCalendarAlt title="New Events" /></Link>
            <Link to="/news-management"><FaNewspaper title="New News Items" /></Link>
            <Link to="/orders"><FaBox title="New Orders" /></Link>
            <Link to="/photos-approval"><FaImages title="New Photos" /></Link>
            <Link to="/trips-management"><FaMapMarkerAlt title="New Trips" /></Link>
          </div>
          <div className="top-bar-profile">
            <Link to="/home"><FaHome title="Home" /></Link>
            {/* You can replace with the admin's profile photo */}
            <img src="/path-to-profile-photo.jpg" alt="Profile" className="profile-photo" />
          </div>
        </div>

        <div className="admin-dashboard-grid">
          {/* Managing Users Tile */}
          <div className="tile">
            <Link to="/users"><h3>Manage Users</h3></Link>
            <p>Total Users: {userData.totalUsers}</p>
            <p>New Users: {userData.newUsers}</p>
          </div>

          {/* Manage Events & Trips Tile */}
          <div className="tile">
            <Link to="/manage-events-trips"><h3>Manage Events & Trips</h3></Link>
            <ul>
              {events.slice(0, 5).map((event, index) => (
                <li key={index}>{event.title}</li>
              ))}
            </ul>
          </div>

          {/* Orders & Shipments Tile */}
          <div className="tile">
            <Link to="/orders"><h3>Orders & Shipments</h3></Link>
            <p>New Orders: {orders.newOrders}</p>
          </div>

          {/* Approve Photos Tile */}
          <div className="tile">
            <Link to="/photos-approval"><h3>Approve Photos</h3></Link>
            <p>New Photos to Approve: {photos.newPhotos}</p>
          </div>

          {/* Manage News Tile */}
          <div className="tile">
            <Link to="/news-management"><h3>Manage News</h3></Link>
            <p>New News Items to Approve: {newsItems.newNews}</p>
          </div>

          {/* Sponsors Management Tile */}
          <div className="tile">
            <Link to="/sponsors-management"><h3>Add New Sponsor</h3></Link>
            <p>Total Sponsors: {sponsors}</p>
          </div>

          {/* Email Management Tile */}
          <div className="tile">
            <Link to="/emails-management"><h3>Email Management</h3></Link>
            <ul>
              {emails.map((email, index) => (
                <li key={index}>{email.subject}</li>
              ))}
            </ul>
          </div>

          {/* Memorial Page Tile */}
          <div className="tile">
            <Link to="/memorial-management"><h3>Memorial Page</h3></Link>
            <p>New Memorial Items: {memorialItems}</p>
          </div>

          {/* Site Activity Tile */}
          <div className="tile">
            <Link to="/site-activity"><h3>Site Activity</h3></Link>
            <Chart />
          </div>
        </div>

        {/* This is where child components from routes will render */}
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboard;
