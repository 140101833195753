import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Assuming you've set up Firestore
import { collection, getDocs } from 'firebase/firestore';
import './Sponsor.css'; // Include your CSS for styling

const SponsorsComponent = () => {
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    const fetchSponsors = async () => {
      const sponsorCollection = collection(db, 'sponsors');
      const sponsorSnapshot = await getDocs(sponsorCollection);
      const sponsorList = sponsorSnapshot.docs.map(doc => doc.data());
      setSponsors(sponsorList.slice(0, 8)); // Limit to 8 sponsors
    };

    fetchSponsors();
  }, []);

  return (
    <div className="sponsors-section">
      <h2>Our Sponsors</h2>
      <div className="sponsor-grid">
        {sponsors.map((sponsor, index) => (
          <div key={index} className="sponsor-tile">
            <img src={sponsor.photoUrl} alt={sponsor.name} className="sponsor-logo" />
            <h3>{sponsor.businessName}</h3>
            <p>{sponsor.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SponsorsComponent;

