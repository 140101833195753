import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, updatePassword, updateEmail } from 'firebase/auth';
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/cropImageUtils'; // Helper to crop the image
import './UserProfile.css'; 

const UserProfile = () => {
  const { currentUser } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [profilePhoto, setProfilePhoto] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newProfilePhoto, setNewProfilePhoto] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState('');
  const [showCropper, setShowCropper] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();

  // Fetch user profile data
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());

          if (userDoc.data().photoUrl) {
            const profilePicRef = ref(storage, userDoc.data().photoUrl);
            getDownloadURL(profilePicRef).then((url) => setProfilePhoto(url));
          }
        }
      }
    };
    fetchUserData();
  }, [currentUser, db, storage]);

  // Handle profile photo change
  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfilePhoto(file); // Set the new photo
      setPreviewPhoto(URL.createObjectURL(file)); // Show preview
      setShowCropper(true); // Show the cropper
    }
  };

  // Handle cropping
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // Upload the cropped image to Firebase and update Firestore
  const uploadCroppedPhoto = async () => {
    if (!newProfilePhoto) {
      alert('No profile photo selected!');
      return;
    }
    
    try {
      // Crop the image based on the user's selection
      const croppedImage = await getCroppedImg(previewPhoto, croppedAreaPixels);
      if (croppedImage) {
        const storageRef = ref(storage, `profilePhotos/${currentUser.uid}`);
        
        // Upload the cropped image to Firebase Storage
        const uploadTask = uploadBytesResumable(storageRef, croppedImage);

        uploadTask.on('state_changed',
          (snapshot) => {
            // Optionally track upload progress here
          },
          (error) => {
            console.error('Error uploading cropped profile photo:', error);
          },
          async () => {
            // Get the download URL after the upload is complete
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setProfilePhoto(downloadURL); // Set the profile photo to the cropped one

            // Update the user's Firestore document with the new photo URL
            const userRef = doc(db, 'users', currentUser.uid);
            await updateDoc(userRef, {
              photoUrl: downloadURL, // Update Firestore with the new photo URL
            });

            alert('Profile photo updated and saved successfully');
            setShowCropper(false); // Hide cropper after upload
          }
        );
      }
    } catch (error) {
      console.error('Error saving cropped photo:', error);
      alert('Failed to save photo. Please try again.');
    }
  };

  // Handle profile update (first name, last name, email)
  const handleUpdateProfile = async () => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
      });
      if (auth.currentUser) {
        await updateEmail(auth.currentUser, userData.email);
      }
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  // Handle password change
  const handleChangePassword = async () => {
    try {
      if (auth.currentUser && newPassword.length >= 6) {
        await updatePassword(auth.currentUser, newPassword);
        alert('Password updated successfully');
      } else {
        alert('Password must be at least 6 characters long');
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Failed to update password');
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-info">
        <h2>Profile Information</h2>

        {/* Display the circular profile photo */}
        <div className="profile-photo-container">
          <img 
            src={profilePhoto || 'default-profile-pic-url'} 
            alt="Profile" 
            className="profile-photo-circle" 
          />
          {/* Button container to align Update and Save buttons */}
          <div className="photo-buttons-container">
            <button 
              className="update-photo-button" 
              onClick={() => document.getElementById('profile-photo-upload').click()}
            >
              Update Photo
            </button>

            {showCropper && (
              <button className="save-photo-button" onClick={uploadCroppedPhoto}>Save Photo</button>
            )}
          </div>
        </div>

        <input
          type="file"
          id="profile-photo-upload"
          style={{ display: 'none' }}
          onChange={handleProfilePhotoChange}
        />

        {showCropper && (
          <div className="cropper-container">
            <Cropper
              image={previewPhoto}
              crop={crop}
              zoom={zoom}
              aspect={1}  // Ensures the crop area is a square for the profile photo circle
              cropShape="round"  // Makes the crop area circular
              showGrid={false}  // Removes grid for a cleaner UI
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        )}

        {/* Other Profile Update Info */}
        <input
          type="text"
          value={userData.firstName || ''}
          onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
          placeholder="First Name"
        />
        <input
          type="text"
          value={userData.lastName || ''}
          onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
          placeholder="Last Name"
        />
        <input
          type="email"
          value={userData.email || ''}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          placeholder="Email"
        />
        <button onClick={handleUpdateProfile}>Update Profile</button>
      </div>

      <div className="password-change">
        <h2>Change Password</h2>
        <input
          type="password"
          placeholder="New Password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button onClick={handleChangePassword}>Change Password</button>
      </div>
    </div>
  );
};

export default UserProfile;
