import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Ensure the correct import path
import { CartContext } from '../context/CartContext'; // CartContext for cart items
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './Navbar.css'; // Existing styles

const Navbar = () => {
  const { currentUser } = useContext(AuthContext); // Access the current user
  const { cart } = useContext(CartContext); // Access the cart from CartContext
  const [profilePhoto, setProfilePhoto] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // State to track if user is admin
  const navigate = useNavigate();
  const storage = getStorage();
  const db = getFirestore();

  // Calculate the total number of items in the cart
  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        // Get the user document from Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Check if 'photoUrl' exists in Firestore
          if (userData.photoUrl) {
            const profilePicRef = ref(storage, userData.photoUrl);
            getDownloadURL(profilePicRef)
              .then((url) => setProfilePhoto(url))
              .catch(() => setProfilePhoto('/default-avatar.png')); // Fallback to default avatar
          } else {
            setProfilePhoto('/default-avatar.png'); // Default avatar if no photoUrl in Firestore
          }

          // Check if the user is an admin
          if (userData.isAdmin) {
            setIsAdmin(true);
          }
        } else {
          setProfilePhoto('/default-avatar.png'); // Default avatar if user doc doesn't exist
        }
      }
    };

    fetchUserData();
  }, [currentUser, db, storage]);

  // Handle redirect to the user's profile page when the profile photo is clicked
  const handleProfileClick = () => {
    if (currentUser) {
      navigate('/userprofile');
    }
  };

  // Handle redirect to the sign-in page
  const handleSignIn = () => {
    navigate('/signin');
  };

  // Handle redirect to the sign-up page
  const handleSignUp = () => {
    navigate('/signup');
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo" onClick={() => navigate('/')}>
        <h2>Sixer Alumni</h2>
      </div>
      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/events">Events</Link></li>
        <li><Link to="/trips">Trips</Link></li>
        <li><Link to="/shop">Shop</Link></li>
        {isAdmin && ( // Show the Admin Dashboard link if the user is an admin
          <li><Link to="/admin-dashboard">Admin Dashboard</Link></li>
        )}
      </ul>

      {/* Cart Icon */}
      <div className="navbar-cart">
        <Link to="/shop/cart" className="cart-icon">
          🛒
          {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
        </Link>
      </div>

      {/* Profile or Auth Links */}
      {currentUser ? (
        <div className="navbar-profile" onClick={handleProfileClick}>
          <img
            src={profilePhoto}
            alt="Profile"
            className="profile-photo"
          />
        </div>
      ) : (
        <div className="navbar-auth">
          <button className="signin-btn" onClick={handleSignIn}>Sign In</button>
          <button className="signup-btn" onClick={handleSignUp}>Sign Up</button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
