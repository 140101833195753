import React, { createContext, useState, useEffect } from 'react';

// Create a context for the cart
export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Initialize cart from localStorage if available
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Add item to cart
  const addToCart = (product, size, quantity) => {
    const existingItem = cart.find((item) => item.id === product.id && item.size === size);
    
    if (existingItem) {
      // Update quantity if the product with the same size already exists
      setCart(
        cart.map((item) =>
          item.id === product.id && item.size === size
            ? { ...item, quantity: item.quantity + quantity }
            : item
        )
      );
    } else {
      // Add new product to cart
      setCart([...cart, { ...product, size, quantity }]);
    }
  };

  // Remove item from cart
  const removeFromCart = (productId, size) => {
    setCart(cart.filter((item) => item.id !== productId || item.size !== size));
  };

  // Clear cart after checkout or when needed
  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
