import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Import Firebase auth and Firestore
import './SignIn.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // State to handle error messages
  const [loading, setLoading] = useState(false); // State to handle loading status
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors
    setLoading(true); // Set loading state

    try {
      // Sign in the user with Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch user document from Firestore to check if the user is an admin
      const userDoc = await getDoc(doc(db, 'users', user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.isAdmin) {
          // If the user is an admin, navigate to the admin dashboard
          navigate('/admin-dashboard');
        } else {
          // If the user is not an admin, navigate to the regular homepage
          navigate('/');
        }
      } else {
        // If no document exists for the user
        setError('No user data found in the database.');
        console.error('No such user document exists!');
      }
    } catch (err) {
      // Catch and handle errors (such as invalid credentials, network issues, etc.)
      console.error('Error signing in:', err.message);
      setError(err.message); // Set the error message to display to the user
    } finally {
      setLoading(false); // Reset the loading state
    }
  };

  return (
    <div className="signin-container">
      <form onSubmit={handleSignIn} className="signin-form">
        <h1>Sign In</h1>

        {error && <p className="error-message">{error}</p>} {/* Display error message */}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit" disabled={loading}>
          {loading ? 'Signing In...' : 'Sign In'}
        </button>
      </form>
    </div>
  );
};

export default SignIn;
