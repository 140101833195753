import React from 'react';

const OrderConfirmation = () => {
  return (
    <div className="order-confirmation">
      <h1>Thank you for your purchase!</h1>
      <p>Your order has been successfully placed. You will receive a confirmation email soon.</p>
      <a href="/shop">Return to Shop</a>
    </div>
  );
};

export default OrderConfirmation;
