import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import './ShoppingCart.css'; // Add styling for the cart page

const ShoppingCart = () => {
  const { cart } = useContext(CartContext); // Access the cart from CartContext
  const navigate = useNavigate();

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const handleCheckout = () => {
    navigate('/shop/checkout'); // Navigate to checkout page
  };

  return (
    <div className="shopping-cart-page">
      <h2>Shopping Cart</h2>
      <div className="cart-items">
        {cart.length === 0 ? (
          <p>Your cart is empty. <a href="/shop">Continue Shopping</a></p>
        ) : (
          <>
            {cart.map((item, index) => (
              <div key={index} className="cart-item">
                <h3>{item.title}</h3>
                <p><strong>Size:</strong> {item.selectedVariant}</p>
                <p><strong>Quantity:</strong> {item.quantity}</p>
                <p><strong>Price:</strong> ${item.price}</p>
              </div>
            ))}
            <div className="cart-summary">
              <h3>Total: ${getTotalPrice()}</h3>
              <button onClick={handleCheckout} className="checkout-button">
                Proceed to Checkout
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ShoppingCart;
