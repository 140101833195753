// OrderTracking.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import './OrderTracking.css';

const OrderTracking = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const orderCollection = collection(db, 'orders');
      const orderSnapshot = await getDocs(orderCollection);
      const orderList = orderSnapshot.docs.map(doc => doc.data());
      setOrders(orderList);
    };

    fetchOrders();
  }, []);

  return (
    <div className="order-tracking">
      <h2>Orders & Shipments</h2>
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Order ID</th>
            <th>Status</th>
            <th>Shipment</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td>{order.userId}</td>
              <td>{order.id}</td>
              <td>{order.status}</td>
              <td>{order.shipment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTracking;
