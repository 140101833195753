import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure you're importing the correct firebase config
import './AboutUs.css';

const AboutUs = () => {
  const [photos, setPhotos] = useState([]);
  const [currentSet, setCurrentSet] = useState(0); // Track the current set of photos being displayed

  // Fetch photos from Firestore using Firebase v9+ syntax
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const photosCollection = collection(db, 'photos'); // Access 'photos' collection
        const photosSnapshot = await getDocs(photosCollection);
        const photosData = photosSnapshot.docs.map(doc => doc.data().url); // Ensure 'url' is the correct field
        setPhotos(photosData);
      } catch (error) {
        console.error('Error fetching photos: ', error);
      }
    };
    fetchPhotos();
  }, []);

  // Cycle through photos every 8 seconds (can be adjusted as needed)
  useEffect(() => {
    if (photos.length > 0) {
      const interval = setInterval(() => {
        setCurrentSet((prevSet) => (prevSet + 1) % Math.ceil(photos.length / 6)); // Cycle through sets of 6 photos
      }, 8000); // Update every 8 seconds (adjust timing as necessary)

      return () => clearInterval(interval); // Cleanup on component unmount
    }
  }, [photos]);

  // Get the current set of photos (3 on left, 3 on right)
  const getPhotoSet = () => {
    const startIndex = currentSet * 6;
    return photos.slice(startIndex, startIndex + 6); // Get 6 photos for the current set
  };

  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <h1>Sixer Pride</h1>
        <p>
        We are an independent alumni network of Independence High School graduates, dedicated to fostering lifelong connections and opportunities for our alumni. While we are not officially affiliated with Columbus City Schools, our mission is to promote fellowship, networking, and support among all IHS graduates. Through events, community engagement, and professional development, we strive to strengthen the bonds between alumni and continue the legacy of Independence High School. Whether you're looking to reconnect, grow professionally, or give back, our network is here to support and celebrate our shared experiences as proud IHS alumni.
        </p>
      </div>

      {/* Display six photos flying in and out */}
      {photos.length > 0 && (
        <div className="photo-animations">
          {/* Render three photos on the left side */}
          {getPhotoSet().slice(0, 3).map((photo, i) => (
            <img
              key={`left-${i}`}
              src={photo}
              alt="Alumni Photos"
              className="photo-fly-in photo-left"
              style={{ animationDelay: `${i * 0.5}s` }} // Stagger the fly-in of each photo
            />
          ))}

          {/* Render three photos on the right side */}
          {getPhotoSet().slice(3, 6).map((photo, i) => (
            <img
              key={`right-${i}`}
              src={photo}
              alt="Alumni Photos"
              className="photo-fly-in photo-right"
              style={{ animationDelay: `${i * 0.5}s` }} // Stagger the fly-in of each photo
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AboutUs;
