import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2>Admin Dashboard</h2>
      <ul>
        <li><Link to="/admin-dashboard/manage-users">Manage Users</Link></li>
        <li><Link to="/admin-dashboard/site-activity">Site Activity</Link></li>
        <li><Link to="/admin-dashboard/order-tracking">Orders & Shipments</Link></li>
        <li><Link to="/admin-dashboard/approve-photos">Approve Photos</Link></li>
        <li><Link to="/admin-dashboard/manage-events-trips">Manage Events & Trips</Link></li>
        <li><Link to="/admin-dashboard/site-editing">Edit Site</Link></li>
        <li><Link to="/admin-dashboard/email-management">Email Management</Link></li>
        <li><Link to="/admin-dashboard/sponsors">Manage Sponsors</Link></li>
        <li><Link to="/admin-dashboard/memorial">Memorial Page</Link></li>
        <li><Link to="/admin-dashboard/news">News Updates</Link></li>
      </ul>
    </div>
  );
};

export default Sidebar;
