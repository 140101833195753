import React from 'react';
import './Home.css'; // Assuming you still want to use CSS for general styling
import HeroSection from '../components/HeroSection'; // Importing HeroSection
import EventsSection from '../components/Events'; // Importing EventsSection
import Trips from '../components/Trips'; // Importing Trips component
import AlumniSpotlight from '../components/AlumniSpotlight';
import Footer from '../components/Footer'; // Importing Footer component
import ContactUs from '../components/ContactUs'; // Importing ContactUs component
import SponsorsComponent from '../components/Sponsor'; // Importing the Sponsor component

const Home = () => {
  return (
    <div className="home-content">
      <div className="hero-section">
        <HeroSection />
      </div>
      <div className="events-section">
        <EventsSection /> {/* Displays only the first two events */}
      </div>
      <div className="trips-section">
        <Trips />
      </div>
      <div className="alumni-spotlight">
        <AlumniSpotlight />
      </div>
      {/* Adding the SponsorsComponent before ContactUs */}
      <div className="sponsors-section">
        <SponsorsComponent /> {/* Displays the sponsor section */}
      </div>
      <div className="contact-us-section">
        <ContactUs /> {/* Adding the ContactUs component */}
      </div>
      <Footer /> {/* Footer at the bottom */}
    </div>
  );
};

export default Home;
