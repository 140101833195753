import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Assuming firebase is configured
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import './PhotoApproval.css'; // CSS file for custom styling

const PhotoApproval = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const photoCollection = collection(db, 'photos');
      const photoSnapshot = await getDocs(photoCollection);
      const photoList = photoSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPhotos(photoList);
    };

    fetchPhotos();
  }, []);

  const approvePhoto = async (photoId) => {
    const photoRef = doc(db, 'photos', photoId);
    await updateDoc(photoRef, { approved: true });
    setPhotos(photos.map(photo => (photo.id === photoId ? { ...photo, approved: true } : photo)));
  };

  const denyPhoto = async (photoId) => {
    const photoRef = doc(db, 'photos', photoId);
    await updateDoc(photoRef, { approved: false });
    setPhotos(photos.filter(photo => photo.id !== photoId)); // Remove the denied photo from the list
  };

  return (
    <div className="photo-approval-container">
      <Card className="photo-approval-card" style={{ resize: 'both', overflow: 'auto' }}>
        <CardContent>
          <h2>Approve Photos</h2>
          <ul className="photo-list">
            {photos.map(photo => (
              <li key={photo.id} className="photo-item">
                <img src={photo.photoUrl} alt="Submitted" className="thumbnail" />
                <div className="photo-actions">
                  {!photo.approved && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => approvePhoto(photo.id)}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => denyPhoto(photo.id)}
                      >
                        Deny
                      </Button>
                    </>
                  )}
                  {photo.approved && <span className="approved-label">Approved</span>}
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
    </div>
  );
};

export default PhotoApproval;
