import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Card, CardContent, CardMedia, Button, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import './Events.css';

const sportsSchedule = [
  { date: '8/23', opponent: 'Central Crossing' },
  { date: '8/29', opponent: 'East' },
  { date: '9/6', opponent: 'Saint Charles' },
  { date: '9/13', opponent: 'Eastmoor Academy' },
  { date: '9/20', opponent: 'Marion-Franklin' },
  { date: '9/27', opponent: 'South' },
  { date: '10/3', opponent: 'Briggs' },
  { date: '10/10', opponent: 'Africentric' },
  { date: '10/18', opponent: 'West' },
  { date: '10/25', opponent: 'Walnut Ridge' },
];

const EventsSection = () => {
  const [events, setEvents] = useState([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, 'events'));
      const eventsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventsList);
    };

    fetchEvents();
  }, []);

  // This function handles when a user clicks on an event
  const handleEventClick = (event) => {
    if (user) {
      console.log('User registered for the event:', event.title);
    } else {
      alert('Please log in to register for the event.');
    }
  };

  return (
    <div className="events-section">
      <Grid container spacing={3}>
        {/* Display events if available, or replace "Coming Soon" cards with real events */}
        {events.length > 0 ? (
          events.slice(0, 2).map((event) => (
            <Grid item xs={12} md={4} key={event.id}>
              <Card className="event-card" onClick={() => handleEventClick(event)}>
                {event.videoURL ? (
                  <CardMedia
                    component="video"
                    height="140"
                    controls
                    src={event.videoURL}
                    alt={event.title}
                  />
                ) : event.imageUrl ? (
                  <CardMedia component="img" height="140" image={event.imageUrl} alt={event.title} />
                ) : null}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {event.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {event.description?.length > 200
                      ? `${event.description.substring(0, 200)}...`
                      : event.description}
                    {event.description?.length > 200 && (
                      <Link to={`/events/${event.id}`} className="read-more">
                        Read more
                      </Link>
                    )}
                  </Typography>
                  {event.link && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.open(event.link, '_blank')}
                    >
                      Buy Tickets
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          // Show "Coming Soon" placeholders if no events are available
          <>
            <Grid item xs={12} md={4}>
              <Card className="event-card">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Coming Soon
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Stay tuned for upcoming events.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="event-card">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Coming Soon
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Stay tuned for upcoming events.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {/* Sports Schedule Card remains unchanged */}
        <Grid item xs={12} md={4}>
          <Card className="event-card sports-schedule">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Sixer Football Schedule
              </Typography>
              <div className="animated-schedule-container">
                <List className="scrolling-list">
                  {sportsSchedule.map((game, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={game.date} secondary={game.opponent} />
                    </ListItem>
                  ))}
                </List>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://purchase-tickets-link.com', '_blank')}
              >
                Purchase Tickets
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default EventsSection;
