import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Use useNavigate for programmatic navigation
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path as needed
import "./AlumniSpotlight.css";

function AlumniSpotlight() {
  const [alumni, setAlumni] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate for programmatic navigation

  useEffect(() => {
    const fetchAlumni = async () => {
      const querySnapshot = await getDocs(collection(db, "alumniSpotlight"));
      setAlumni(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchAlumni();
  }, []);

  const handleReadMore = (id) => {
    // Navigate to the Alumni Detail page
    navigate(`/alumni-spotlight/${id}`);
  };

  return (
    <div className="alumni-spotlight">
      <h2>Alumni Spotlight</h2>
      <div className="spotlight-content">
        {alumni.map((person, index) => (
          <div key={index} className="alumni-item">
            <div className="alumni-left">
              <img src={person.photoUrl} alt={person.name} />
            </div>
            <div className="alumni-right">
              <h3>{person.name}</h3>
              <p className="description">
                {person.achievements ? person.achievements : "No achievement information available."}
              </p>
              {/* "Read More" button to navigate to detail page */}
              <button onClick={() => handleReadMore(person.id)} className="read-more">
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AlumniSpotlight;
