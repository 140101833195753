// Footer.js
import React from 'react';
import './Footer.css'; // Create this CSS file for styling the footer

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 Sixer Alumni. All rights reserved.</p>
        <p>Contact us: SixerAdmin@sixeralumni.com</p>
        <p>Follow us on social media</p>
        {/* Add social media links or icons if needed */}
      </div>
    </footer>
  );
};

export default Footer;
