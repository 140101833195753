import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './EventsAndTrips.css';

const EventsAndTrips = () => {
  const [events, setEvents] = useState([]);
  const [trips, setTrips] = useState([]);
  const [editEvent, setEditEvent] = useState(null);  // Store the event being edited
  const [editTrip, setEditTrip] = useState(null);    // Store the trip being edited

  const [newEvent, setNewEvent] = useState({ title: '', date: '', time: '', location: '', host: '', ticketLink: '', description: '', photoUrl: '' });
  const [newTrip, setNewTrip] = useState({ title: '', date: '', location: '', description: '', photoUrl: '' });
  const [eventPhoto, setEventPhoto] = useState(null);
  const [tripPhoto, setTripPhoto] = useState(null);

  const storage = getStorage();

  useEffect(() => {
    const fetchData = async () => {
      const eventCollection = collection(db, 'events');
      const tripCollection = collection(db, 'upcomingTravel');

      const eventSnapshot = await getDocs(eventCollection);
      const tripSnapshot = await getDocs(tripCollection);

      const eventList = eventSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const tripList = tripSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      setEvents(eventList);
      setTrips(tripList);
    };

    fetchData();
  }, []);

  const uploadPhoto = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `photos/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleAddEvent = async () => {
    let photoUrl = '';
    if (eventPhoto) {
      photoUrl = await uploadPhoto(eventPhoto);
    }
    const eventCollection = collection(db, 'events');
    await addDoc(eventCollection, { ...newEvent, photoUrl });
    setNewEvent({ title: '', date: '', time: '', location: '', host: '', ticketLink: '', description: '', photoUrl: '' });
    setEventPhoto(null);
  };

  const handleAddTrip = async () => {
    let photoUrl = '';
    if (tripPhoto) {
      photoUrl = await uploadPhoto(tripPhoto);
    }
    const tripCollection = collection(db, 'upcomingTravel');
    await addDoc(tripCollection, { ...newTrip, photoUrl });
    setNewTrip({ title: '', date: '', location: '', description: '', photoUrl: '' });
    setTripPhoto(null);
  };

  const handleDeleteEvent = async (id) => {
    const eventDoc = doc(db, 'events', id);
    await deleteDoc(eventDoc);
    setEvents(events.filter(event => event.id !== id));
  };

  const handleDeleteTrip = async (id) => {
    const tripDoc = doc(db, 'upcomingTravel', id);
    await deleteDoc(tripDoc);
    setTrips(trips.filter(trip => trip.id !== id));
  };

  const handleEditEvent = (event) => {
    setEditEvent(event);  // Set the selected event for editing
  };

  const handleEditTrip = (trip) => {
    setEditTrip(trip);  // Set the selected trip for editing
  };

  const handleUpdateEvent = async (id) => {
    const eventDoc = doc(db, 'events', id);
    let photoUrl = editEvent.photoUrl;

    // Update photo if a new photo is uploaded
    if (eventPhoto) {
      photoUrl = await uploadPhoto(eventPhoto);
    }

    await updateDoc(eventDoc, { ...editEvent, photoUrl });
    setEditEvent(null);  // Reset edit state
  };

  const handleUpdateTrip = async (id) => {
    const tripDoc = doc(db, 'upcomingTravel', id);
    let photoUrl = editTrip.photoUrl;

    // Update photo if a new photo is uploaded
    if (tripPhoto) {
      photoUrl = await uploadPhoto(tripPhoto);
    }

    await updateDoc(tripDoc, { ...editTrip, photoUrl });
    setEditTrip(null);  // Reset edit state
  };

  return (
    <div className="events-trips">
      <h2>Manage Events & Trips</h2>

      {/* Add New Event Form */}
      <div className="add-section">
        <h3>Add New Event</h3>
        <input
          type="text"
          placeholder="Event Title"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
        />
        <input
          type="date"
          placeholder="Event Date"
          value={newEvent.date}
          onChange={(e) => setNewEvent({ ...newEvent, date: e.target.value })}
        />
        <input
          type="time"
          placeholder="Event Time"
          value={newEvent.time}
          onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
        />
        <input
          type="text"
          placeholder="Event Location"
          value={newEvent.location}
          onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
        />
        <input
          type="text"
          placeholder="Host"
          value={newEvent.host}
          onChange={(e) => setNewEvent({ ...newEvent, host: e.target.value })}
        />
        <input
          type="text"
          placeholder="Ticket Link (optional)"
          value={newEvent.ticketLink}
          onChange={(e) => setNewEvent({ ...newEvent, ticketLink: e.target.value })}
        />
        <textarea
          placeholder="Event Description"
          value={newEvent.description}
          onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
        />
        <input type="file" onChange={(e) => setEventPhoto(e.target.files[0])} />
        <button onClick={handleAddEvent}>Add Event</button>
      </div>

      {/* Add New Trip Form */}
      <div className="add-section">
        <h3>Add New Trip</h3>
        <input
          type="text"
          placeholder="Trip Title"
          value={newTrip.title}
          onChange={(e) => setNewTrip({ ...newTrip, title: e.target.value })}
        />
        <input
          type="date"
          placeholder="Trip Date"
          value={newTrip.date}
          onChange={(e) => setNewTrip({ ...newTrip, date: e.target.value })}
        />
        <input
          type="text"
          placeholder="Trip Location"
          value={newTrip.location}
          onChange={(e) => setNewTrip({ ...newTrip, location: e.target.value })}
        />
        <textarea
          placeholder="Trip Description"
          value={newTrip.description}
          onChange={(e) => setNewTrip({ ...newTrip, description: e.target.value })}
        />
        <input type="file" onChange={(e) => setTripPhoto(e.target.files[0])} />
        <button onClick={handleAddTrip}>Add Trip</button>
      </div>

      {/* Display and Edit Events */}
      <div className="tiles-section">
        <h3>Events</h3>
        {events.map(event => (
          <div key={event.id} className="tile">
            {editEvent?.id === event.id ? (
              <div>
                <input
                  type="text"
                  value={editEvent.title}
                  onChange={(e) => setEditEvent({ ...editEvent, title: e.target.value })}
                />
                <input
                  type="date"
                  value={editEvent.date}
                  onChange={(e) => setEditEvent({ ...editEvent, date: e.target.value })}
                />
                <textarea
                  value={editEvent.description}
                  onChange={(e) => setEditEvent({ ...editEvent, description: e.target.value })}
                />
                <input type="file" onChange={(e) => setEventPhoto(e.target.files[0])} />
                <button onClick={() => handleUpdateEvent(event.id)}>Save</button>
                <button onClick={() => setEditEvent(null)}>Cancel</button>
              </div>
            ) : (
              <div>
                <h4>{event.title}</h4>
                {event.photoUrl && <img src={event.photoUrl} alt={event.title} />}
                <p>Date: {event.date}</p>
                <p>Location: {event.location}</p>
                <p>{event.description}</p>
                <button onClick={() => handleEditEvent(event)}>Edit</button>
                <button onClick={() => handleDeleteEvent(event.id)}>Delete</button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Display and Edit Trips */}
      <div className="tiles-section">
        <h3>Trips</h3>
        {trips.map(trip => (
          <div key={trip.id} className="tile">
            {editTrip?.id === trip.id ? (
              <div>
                <input
                  type="text"
                  value={editTrip.title}
                  onChange={(e) => setEditTrip({ ...editTrip, title: e.target.value })}
                />
                <input
                  type="date"
                  value={editTrip.date}
                  onChange={(e) => setEditTrip({ ...editTrip, date: e.target.value })}
                />
                <textarea
                  value={editTrip.description}
                  onChange={(e) => setEditTrip({ ...editTrip, description: e.target.value })}
                />
                <input type="file" onChange={(e) => setTripPhoto(e.target.files[0])} />
                <button onClick={() => handleUpdateTrip(trip.id)}>Save</button>
                <button onClick={() => setEditTrip(null)}>Cancel</button>
              </div>
            ) : (
              <div>
                <h4>{trip.title}</h4>
                {trip.photoUrl && <img src={trip.photoUrl} alt={trip.title} />}
                <p>Date: {trip.date}</p>
                <p>Location: {trip.location}</p>
                <p>{trip.description}</p>
                <button onClick={() => handleEditTrip(trip)}>Edit</button>
                <button onClick={() => handleDeleteTrip(trip.id)}>Delete</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsAndTrips;
