import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure Firebase is configured
import { Grid, Card, CardMedia, Typography, Button } from '@mui/material';
import './Trips.css';

const Trips = () => {
  const [trips, setTrips] = useState([]);
  const navigate = useNavigate(); // Navigation hook

  // Fetch trips from Firestore
  useEffect(() => {
    const fetchTrips = async () => {
      const querySnapshot = await getDocs(collection(db, 'upcomingTravel'));
      const tripsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTrips(tripsList);
    };

    fetchTrips();
  }, []);

  const handleLearnMore = (tripId) => {
    navigate(`/trips/${tripId}`); // Navigate to trip detail page with trip ID
  };

  return (
    <div className="trips-section">
      <Grid container spacing={3}>
        {trips.map((trip) => (
          <Grid item xs={12} key={trip.id}>
            <Card className="trip-card">
              <div className="trip-overlay">
                <CardMedia
                  component="img"
                  image={trip.photoUrl || 'https://via.placeholder.com/300x300'}
                  alt={trip.tripTitle}
                  className="trip-thumbnail"
                />
                <div className="trip-details">
  <Typography variant="h5" component="div" className="centered-text">
    {trip.tripTitle}
  </Typography>
  <Typography variant="body2" className="centered-text">
    {trip.location}
  </Typography>
  <Typography variant="body2" className="centered-text">
    {new Date(trip.timeBegin.seconds * 1000).toLocaleDateString()} - {new Date(trip.timeEnd.seconds * 1000).toLocaleDateString()}
  </Typography>
  <Button
    variant="contained"
    color="primary"
    className="learn-more-button"
    onClick={() => handleLearnMore(trip.id)}
  >
    Learn More
  </Button>
</div>

              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Trips;
