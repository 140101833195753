import React, { useRef, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Charts = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      // You can manipulate the chart instance here if needed
    }
  }, []);

  const data = {
    labels: ['January', 'February', 'March', 'April'],
    datasets: [
      {
        label: 'Sales',
        data: [30, 20, 50, 60],
        backgroundColor: 'rgba(75,192,192,1)',
      }
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales per Month',
      },
    },
  };

  return <Bar ref={chartRef} data={data} options={options} />;
};

export default Charts;
