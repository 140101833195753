import React from 'react';
import './HeroSection.css';
import videoBackground from '../images/Untitled ‑ Made with FlexClip.mp4'; // Adjust the path to where your video is located
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <div className="hero-container">
      {/* Video Background */}
      <video autoPlay loop muted className="video-bg">
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Hero Content */}
      <div className="hero-content">
        <h1>Welcome Back, Sixer Alumni!</h1>
        <p>Reconnect, share stories, and engage with your fellow alumni.</p>
        <div className="hero-buttons">
          <Link to="/signup" className="btn btn-signup">Sign Up</Link>
          <Link to="/signin" className="btn btn-signin">Sign In</Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
