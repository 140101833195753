import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Ensure Firebase is configured
import { useAuthState } from 'react-firebase-hooks/auth';
import { Card, CardContent, Typography, Button } from '@mui/material';

const TripDetail = () => {
  const { tripId } = useParams(); // Get trip ID from URL
  const [trip, setTrip] = useState(null);
  const [user] = useAuthState(auth);
  const [isRegistered, setIsRegistered] = useState(false);

  useEffect(() => {
    const fetchTripDetails = async () => {
      const docRef = doc(db, 'upcomingTravel', tripId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setTrip(docSnap.data());
      } else {
        console.log('No such trip!');
      }
    };

    fetchTripDetails();
  }, [tripId]);

  const handleRegister = async () => {
    if (user) {
      const docRef = doc(db, 'upcomingTravel', tripId);
      await updateDoc(docRef, {
        registrations: arrayUnion({
          uid: user.uid,
          email: user.email,
          name: user.displayName || user.email,
        }),
      });
      setIsRegistered(true);
    } else {
      alert('You need to be logged in to register.');
    }
  };

  if (!trip) return <div>Loading...</div>;

  return (
    <Card className="trip-detail-card">
      <CardContent>
        <Typography gutterBottom variant="h4" component="div">
          {trip.tripTitle}
        </Typography>
        <img src={trip.photoUrl || 'https://via.placeholder.com/140'} alt={trip.title} />
        <Typography variant="body1" color="text.secondary">
          {trip.tripDescription}
        </Typography>
        {/* Display hotel name as a hyperlink */}
        <Typography variant="h6" color="primary">
          Hotel Link: <a href={trip.websiteUrl} target="_blank" rel="noopener noreferrer">{trip.hotelName}</a>
        </Typography>

        {/* Registration Button */}
        {isRegistered ? (
          <Typography variant="h6" color="secondary">
            You are registered for this trip!
          </Typography>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegister}
          >
            Register for Trip
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default TripDetail;
